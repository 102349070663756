.content-about-wrapper {
  width: 80%;
  z-index: 1;

  @include bp(small-down) {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }

  &__right-section {
    @include bp(large) {
      width: 50%;
      margin-right: 6rem;
    }
    @include bp(largest) {
      width: 50%;
      margin-right: 6rem;
    }
  }

  &__left-section {
    @include bp(large) {
      width: 50%;
      margin-left: 6rem;
    }
    @include bp(largest) {
      width: 50%;
      margin-left: 6rem;
    }
  }
}

.about-titles {
  font-family: Lexend;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  &__center {
    text-align: center;
  }

  &__left {
    text-align: left;
  }
}

.about-subtitles {
  font-family: Lexend;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-left: 10px;

  &__center {
    text-align: center;
  }

  &__left {
    text-align: left;
  }
}

.about-paragraph {
  font-family: Lexend;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  &__center {
    text-align: center;
  }

  &__left {
    text-align: left;
  }
}

.section_bg_white {
  background-color: $whiteNew;
}

.section_bg_secondary10 {
  background-color: $secondary10 !important;
}

.margin-trackers-about {
  border-radius: 8px;
  border: 1px solid $primary10;
  background: $whiteNew;
  padding: 1rem 15px 1.5rem 15px !important;
}

.div-bottom-margin {
  &__1rem {
    margin-bottom: 1rem;
  }

  &__2rem {
    margin-bottom: 2rem;
  }

  &__3rem {
    margin-bottom: 3rem;

    @include bp(small-down) {
      margin-bottom: 1rem;
    }
  }

  &__5rem {
    margin-bottom: 5rem;
  }

  &__8rem {
    margin-bottom: 8rem;

    @include bp(small-down) {
      margin-bottom: 2rem;
    }
  }

  &__10rem {
    margin-bottom: 10rem;
  }
}

//Styles for about images

.images-wrapper {
  height: calc(100vw);
  position: relative;
  top: 40px;
  @include bp(large) {
    height: unset;
    width: 50%;
    max-width: 550px;
    margin: auto;
  }
}

.image__about {
  display: inline-block;
  transition: all 0.8s ease-in-out;

  &.visible {
    transform: scale(1) translate(0px, 0);
    z-index: -1;
  }

  @include bp(large) {
    transform: scale(1) translate(-10px, 10px);
    &.visible {
      transform: scale(1) translate(-10px, 10px);
    }
  }
  @include bp(largest) {
    transform: scale(1.3) translate(-70px, 15px);
    &.visible {
      transform: scale(1.3) translate(-70px, 15px);
    }
  }
}

.image__skin-tracker {
  display: inline-block;
  transition: all 0.8s ease-in-out;

  &.visible {
    transform: scale(1) translate(0px, 0);
    z-index: -1;
  }

  @include bp(large) {
    transform: scale(1) translate(15px, 10px);
    &.visible {
      transform: scale(1) translate(15px, 10px);
    }
  }
  @include bp(largest) {
    transform: scale(1.3) translate(50px, 15px);
    &.visible {
      transform: scale(1.3) translate(50px, 15px);
    }
  }
}

.image__skin-dex {
  display: inline-block;
  transition: all 0.8s ease-in-out;

  &.visible {
    transform: scale(1) translate(100px, 0);
    z-index: -1;
  }

  @include bp(large) {
    transform: scale(1) translate(1px, -25px);
    &.visible {
      transform: scale(1) translate(1px, -25px);
    }
  }
  @include bp(largest) {
    transform: scale(1.2) translate(80px, -20px);
    &.visible {
      transform: scale(1.2) translate(80px, -20px);
    }
  }
}

.image__research {
  display: inline-block;
  transition: all 0.8s ease-in-out;

  &.visible {
    transform: scale(1) translate(0px, 0);
    z-index: -1;
  }

  @include bp(large) {
    transform: scale(1) translate(10px, -50px);
    &.visible {
      transform: scale(1) translate(10px, -50px);
    }
  }
  @include bp(largest) {
    transform: scale(1.3) translate(70px, -30px);
    &.visible {
      transform: scale(1.3) translate(70px, -30px);
    }
  }
}

.image__track-app {
  display: inline-block;
  transition: all 0.8s ease-in-out;

  &.visible {
    transform: scale(1) translate(0px, 0);
    z-index: -1;
  }

  @include bp(large) {
    transform: scale(1) translate(10px, -50px);
    &.visible {
      transform: scale(1) translate(10px, -50px);
    }
  }
  @include bp(largest) {
    transform: scale(1.2) translate(70px, -40px);
    &.visible {
      transform: scale(1.2) translate(70px, -40px);
    }
  }
}

.image__surveys {
  display: inline-block;
  transition: all 0.8s ease-in-out;

  &.visible {
    transform: scale(1) translate(0px, 0);
    z-index: -1;
  }

  @include bp(large) {
    transform: scale(1) translate(1px, -25px);
    &.visible {
      transform: scale(1) translate(1px, -25px);
    }
  }
  @include bp(largest) {
    transform: scale(1.2) translate(80px, -20px);
    &.visible {
      transform: scale(1.2) translate(80px, -20px);
    }
  }
}

.image__advisors {
  display: inline-block;
  transition: all 0.8s ease-in-out;

  &.visible {
    transform: scale(1) translate(0px, 0);
    z-index: -1;
  }

  @include bp(large) {
    transform: scale(1) translate(10px, -50px);
    &.visible {
      transform: scale(1) translate(10px, -50px);
    }
  }
  @include bp(largest) {
    transform: scale(1.2) translate(70px, -40px);
    &.visible {
      transform: scale(1.2) translate(70px, -40px);
    }
  }
}

.sponsors-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 50px 0;
  gap: 1em;

  img.sponsor-logo {
    height: 60px;
    width: auto;

    @include bp(small-down) {
      height: 48px;
    }
  }
}
