.date-bar {
  align-items: center;
  background-color: $white;
  display: flex;
  height: 50px;
  padding: 0 0.375rem 0 0;
  border-bottom: 4px solid $primary10;
  @extend .shadow-default;

  .button__wrapper {
    padding-left: 1rem;
  }

  .date-bar__intro {
    @include bp(small-down) {
      display: none;
    }
  }

  .date-bar__date {
    margin: 0 0 0 0.5rem;
    @include bp(small-up) {
      margin: 0 1.125rem;
    }
  }

  span {
    font-weight: bold;
    text-transform: uppercase;
  }

  p {
    margin: 0;
    font-weight: 200;
    font-size: 14px;
    color: #533acc;
  }

  time {
    display: inline-block;
    min-width: 7.5rem;
    text-transform: capitalize;
  }

  .scale-button {
    align-items: center;
    display: flex;
    height: 1.25rem;
    justify-content: center;
    width: 1.25rem;
  }

  .buttons__forward {
    display: flex;
    justify-content: space-between;
    width: 3rem;
  }

  .sidebar-margins {
    margin-left: -18px;
  }

  .container-date-info {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    align-content: center;
    padding-left: 10px;
  }

  .calendar-header {
    position: relative;

    .container-date-info {
      cursor: pointer;
    }
  }
}

.trackersCalendar {
  position: absolute;
  top: 48px;
  width: 364px;
  height: auto;
  background: $white;
  border-radius: 8px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  border: none;
  display: flex;
  flex-direction: column;
  z-index: 10;

  .trackersCalendar-header {
    display: flex;
    flex-direction: row;
    padding: 20px;
    background: $secondary100;
    color: $white;
    position: relative;

    h4 {
      width: 100%;
      margin: 0;
      padding: 0;
      font-size: 16px;
      text-align: center;
      text-transform: uppercase;
    }

    .trackersCalendar-close {
      position: absolute;
      right: 20px;
      top: 50%;
      margin-top: -14px;
      cursor: pointer;
    }
  }

  .trackersCalendar-body {
    flex: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .trackersCalendar-nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .trackersCalendar-nav-button {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border: none;
      color: $secondary100;
      background-color: $primary10;

      &:disabled {
        opacity: 0.6;
      }
    }

    .trackersCalendar-nav-title {
      flex: 1;
      margin: 0;
      padding: 0;
      font-size: 16px;
      text-align: center;
      color: $primary100;
    }
  }

  .trackersCalendar-week-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    .trackersCalendar-week-header-day {
      flex: 1;
      text-align: center;
      font-size: 13px;
      color: $black;
    }
  }

  .trackersCalendar-days {
    display: grid;
    grid-template-columns: repeat(7, minmax(0, 1fr));
    column-gap: 10px;
    row-gap: 2px;

    .trackersCalendar-day {
      height: 40px;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding-top: 5px;
      cursor: pointer;
      color: $secondary100;
      gap: 2px;
      transition: all 0.2s ease-in;

      &:hover {
        background: $secondary10;
      }

      span {
        font-size: 16px;
        font-weight: bold;
      }

      div.dot {
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: $black50;

        &.Mild {
          background: $severityMild;
        }
        &.Moderate {
          background: $severityModerate;
        }
        &.Severe {
          background: $severitySevere;
        }
      }

      &.selected {
        background: $secondary100;
        color: $white;
      }

      &.disabled-edition {
        opacity: 0.75;
        cursor: default;
        color: $black;

        &:hover,
        &.selected {
          background: $black25;
        }
      }

      &.disabled {
        opacity: 0.6;
        cursor: default;
        color: $black;

        span {
          font-weight: 400;
        }
      }
    }
  }

  .trackersCalendar-results {
    display: flex;
    flex-direction: column;
    justify-content: 'flex-start';
    gap: 15px;
    margin: 10px 0 5px;

    .trackersCalendar-results-date {
      font-size: 16px;
      font-weight: bold;
      color: $black;
    }

    .trackersCalendar-results-values {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;

      .trackersCalendar-results-title {
        font-size: 15px;
        font-weight: 400;
      }

      .trackersCalendar-results-dot {
        width: 12px;
        height: 12px;
        border-radius: 2px;
        background: $black50;

        &.Mild {
          background: $severityMild;
        }
        &.Moderate {
          background: $severityModerate;
        }
        &.Severe {
          background: $severitySevere;
        }
      }
      .trackersCalendar-results-severity {
        font-size: 15px;
        font-weight: bold;
      }
    }
  }

  .trackersCalendar-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    button {
      flex: 1;
      font-weight: bold;
      height: 46px;
    }
  }
}
